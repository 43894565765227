<template>
  <div id="property-details" class="mb-4">
    <v-container fluid>
      <v-row justify="center" align-sm="center">
        <v-col cols="2">
          <v-btn icon to="/" class="justify-center" color="blue-grey lighten-3">
            <v-icon>fas fa-angle-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8" sm="8" class="d-flex justify-center">
          <h3 class="light-blue--text">Details</h3>
        </v-col>
        <v-col cols="2">
          <v-bottom-sheet v-model="share_sheet" inset>
            <template v-slot:activator="{ share, share_attrs }">
              <v-btn
                icon
                @click="share_sheet = !share_sheet"
                v-bind="share_attrs"
                v-on="share"
              >
                <v-icon>fas fa-share-alt</v-icon>
              </v-btn>
            </template>
            <v-sheet class="pa-2 rounded-lg text-center" height="auto">
              <v-row class="my-1">
                <v-col cols="10">
                  <v-text-field
                    v-on:focus="$event.target.select()"
                    ref="myinput"
                    readonly
                    :value="copyUrl"
                  />
                </v-col>
                <v-col cols="2">
                  <v-btn icon @click="copyLink">
                    <v-icon>far fa-copy</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="my-2">
                <v-col
                  cols="3"
                  v-for="(share, index) in shareLinks"
                  :key="index"
                >
                  <v-btn icon :href="share.link">
                    <v-icon>{{ share.icon }}</v-icon>
                  </v-btn></v-col
                >
              </v-row>
            </v-sheet>
          </v-bottom-sheet>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" :color="snackbar_color" rounded="pill" top>
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-row class="mt-1">
        <v-col cols="12" md="4" xl="4" sm="12">
          <template>
            <v-card class="rounded-xl">
              <v-carousel
                height="auto"
                cycle
                touch
                hide-delimiters
                delimiter-icon=""
                show-arrows-on-hover
              >
                <v-carousel-item v-for="photo in propertyPhoto" :key="photo.id">
                  <v-img
                    :src="
                      imgURL +
                      '/' +
                      photo.user_id +
                      '/' +
                      photo.basic_property_id +
                      '/' +
                      photo.image_name
                    "
                    height="auto"
                    alt="100 miles realtors property image"
                  />
                </v-carousel-item>
              </v-carousel>
            </v-card>
          </template>
        </v-col>
        <v-col cols="12" md="8" xl="8" sm="12">
          <v-row class="py-2">
            <span v-for="property in basicDetails" :key="property.id">
              <div class="text-left blue--text darken-4 ml-3 text-h6">
                <b
                  >{{ property.propertytype.property_name }} for
                  {{ property.pfor }}
                </b>
              </div>

              <!-- <b>{{ property.basic_property_id }}</b>-->
            </span>
          </v-row>
          <v-row class="mb-1">
            <span v-for="property in basicDetails" :key="property.id">
              <div class="text-left blue-grey--text darken-4 ml-3 text-body-2">
                <b>
                  At {{ property.locality }} {{ property.city }},
                  {{ property.state.state_name }}</b
                >
              </div>
            </span>
          </v-row>
          <v-divider></v-divider>
          <span v-for="property in basicDetails" :key="property.id">
            <v-row class="mt-4">
              <v-col cols="4" md="4" lg="4" sm="12" class="">
                <span class="caption blue-grey--text darken-4">
                  Property Type
                </span>
                <br />
                <span class="subtitle-2">
                  {{ property.ptype }}
                </span>
              </v-col>

              <v-col cols="4" md="4" lg="4" sm="12" class="">
                <span class="caption blue-grey--text darken-4">
                  Possession Status
                </span>
                <br />
                <span class="subtitle-2">
                  {{ property.possession }}
                </span>
              </v-col>

              <v-col
                cols="4"
                md="4"
                lg="4"
                sm="12"
                v-if="property.p_time != null"
                class=""
              >
                <span class="caption blue-grey--text darken-4">
                  Available From
                </span>
                <br />
                <span class="subtitle-2">
                  {{ property.p_time }}
                </span>
              </v-col>

              <v-col
                cols="4"
                md="4"
                lg="4"
                sm="12"
                v-if="property.p_age != null"
                class=""
              >
                <span class="caption blue-grey--text darken-4">
                  Property Age
                </span>
                <br />
                <span class="subtitle-2">
                  {{ property.p_age }}
                </span>
              </v-col>

              <!-- <b>{{ property.district.city_name }}</b> -->
              <!-- <v-col cols="6" md="4" lg="4" sm="12" class="">
                <span class="caption blue-grey--text darken-4"> Price : </span>
                <br />
                <span class="subtitle-2">
                  {{ this.editedPrice }}
                </span>
              </v-col> -->

              <v-col cols="4" md="4" lg="4" sm="12" class="">
                <span class="caption blue-grey--text darken-4">
                  Price Negotiable
                </span>
                <br />
                <span class="subtitle-2">
                  <p v-if="property.price_negotiable == 1">Yes</p>
                  <p v-else>No</p>
                </span>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>

            <!-- APRTMENT & STUDIO APARTMENT -->
            <div
              v-if="
                property.property_type_id == 1 || property.property_type_id == 6
              "
            >
              <v-row>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Super Builtup Area
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.super_built_area }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Builtup Area
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.built_area }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Carpet Area
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.carpet_area }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Total Floor
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.total_no_floor }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Floor No.
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.floor_no }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4"> Facing </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.facing }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Bedroom
                  </span>
                  <br />
                  <span class="subtitle-2">
                    <v-icon class="mr-2">fas fa-bed</v-icon>
                    {{ property.bedroom }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Bathroom
                  </span>
                  <br />
                  <span class="subtitle-2">
                    <v-icon class="mr-2">fas fa-bath</v-icon>
                    {{ property.bathroom }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Balcony
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.balcony }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Maintenance
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.maintenance }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <!-- FARM LAND -->
            <div v-if="property.property_type_id == 7" class="mt-2">
              <v-row>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Area in Hectare
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.hectare }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <!-- Farm House -->
            <div v-if="property.property_type_id == 8">
              <v-row>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Area in Hectare
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.hectare }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Builtup Area
                  </span>
                  <br />
                  <span class="caption subtitle-2">
                    {{ property.built_area }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Carpet Area
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.carpet_area }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Total Floor
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.total_no_floor }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4"> Facing </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.facing }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Bedroom
                  </span>
                  <br />
                  <span class="subtitle-2">
                    <v-icon class="mr-2">fas fa-bed</v-icon>
                    {{ property.bedroom }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Bathroom
                  </span>
                  <br />
                  <span class="subtitle-2">
                    <v-icon class="mr-2">fas fa-bath</v-icon>
                    {{ property.bathroom }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Balcony
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.balcony }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <!-- Row House/Bungalow/ villa /pent house-->
            <div
              v-if="
                property.property_type_id == 2 ||
                property.property_type_id == 3 ||
                property.property_type_id == 4 ||
                property.property_type_id == 5
              "
            >
              <v-row>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Builtup Area
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.built_area }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Carpet Area
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.carpet_area }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Total Floor
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.total_no_floor }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4"> Facing </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.facing }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Bedroom
                  </span>
                  <br />
                  <span class="subtitle-2">
                    <v-icon class="mr-2">fas fa-bed</v-icon>
                    {{ property.bedroom }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Bathroom
                  </span>
                  <br />
                  <span class="subtitle-2">
                    <v-icon class="mr-2">fas fa-bath</v-icon>
                    {{ property.bathroom }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Balcony
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.balcony }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Area
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.plot_area }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Width
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.p_width }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Length
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.p_length }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Road Width
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.road_width }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Open Sides
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.open_side }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <!--Plot Property-->
            <div
              v-if="
                property.property_type_id == 13 ||
                property.property_type_id == 15 ||
                property.property_type_id == 17
              "
            >
              <v-row>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4"> Facing </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.facing }}
                  </span>
                </v-col>

                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Area
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.plot_area }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Width
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.p_width }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Length
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.p_length }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Road Width
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.road_width }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Open Sides
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.open_side }}
                  </span>
                </v-col>

                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Compound
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.compound }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <!--Office/shop/showroom Property-->
            <div
              v-if="
                property.property_type_id == 9 ||
                property.property_type_id == 10 ||
                property.property_type_id == 11 ||
                property.property_type_id == 12
              "
            >
              <v-row>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Super Builtup Area
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.super_built_area }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Builtup Area
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.built_area }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Carpet Area
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.carpet_area }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Total Floor
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.total_no_floor }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Floor No.
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.floor_no }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4"> Facing </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.facing }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Cafeteria
                  </span>
                  <br />
                  <span class="subtitle-2" v-if="property.cafeteria == 0">
                    No
                  </span>
                  <span class="subtitle-2" v-else> Yes </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Maintenance
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.maintenance }}
                  </span>
                </v-col>
                <v-col
                  cols="4"
                  md="4"
                  lg="4"
                  sm="12"
                  class=""
                  v-if="
                    property.property_type_id == 11 ||
                    property.property_type_id == 12
                  "
                >
                  <span class="caption blue-grey--text darken-4">
                    Corner Property
                  </span>
                  <br />
                  <span class="subtitle-2" v-if="property.corner_property == 0">
                    No
                  </span>
                  <span class="subtitle-2" v-else> Yes </span>
                </v-col>
                <v-col
                  cols="4"
                  md="4"
                  lg="4"
                  sm="12"
                  class=""
                  v-if="
                    property.property_type_id == 11 ||
                    property.property_type_id == 12
                  "
                >
                  <span class="caption blue-grey--text darken-4">
                    Road Facing
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.road_facing }}
                  </span>
                </v-col>
              </v-row>
            </div>

            <!-- WAREHOUSE AND INDUSTRIAL BUILDING -->
            <div
              v-if="
                property.property_type_id == 14 ||
                property.property_type_id == 16
              "
            >
              <v-row>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Builtup Area
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.built_area }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Carpet Area
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.carpet_area }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4"> Facing </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.facing }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Area
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.plot_area }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Width
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.p_width }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Length
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.p_length }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Open Sides
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.open_side }}
                  </span>
                </v-col>
                <v-col cols="4" md="4" lg="4" sm="12" class="">
                  <span class="caption blue-grey--text darken-4">
                    Road Width
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.road_width }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-row>
              <v-col cols="12" class="px-3">
                <span class="caption blue-grey--text darken-4">
                  Description
                </span>
                <br />
                <span class="subtitle-2">
                  {{ property.description }}
                </span>
              </v-col>
            </v-row>
          </span>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
      <span>
        <v-row class="">
          <v-col
            cols="6"
            md="3"
            xl="3"
            sm="12"
            v-for="amenity in amenityDetails"
            :key="amenity.id"
          >
            <span class="subtitle-2 blue-grey--text darken-4"
              >{{ amenity.amenitytype.amenity_type }} </span
            ><br />
            <v-chip class="ma-2" color="primary" small outlined>
              {{ amenity.amenitytype.amenity_name }}
            </v-chip>
            <br />
          </v-col>
        </v-row>
      </span>

      <v-row class="mt-3">
        <v-col cols="12"> </v-col>
      </v-row>
    </v-container>

    <v-row class="white bottom-bar">
      <v-col
        cols="6"
        md="6"
        lg="6"
        sm="6"
        class="d-flex align-center justify-center"
      >
        <!-- <span class="caption blue-grey--text darken-4"> Price : </span>
          <br /> -->
        <v-icon
          left
          color="
                   blue-grey accent-3"
        >
          fas fa-rupee-sign
        </v-icon>
        <span class="text-h5 font-weight-black light-blue--text">
          {{ this.editedPrice }}
        </span>
      </v-col>
      <v-col cols="6" md="6" lg="6" sm="6" class="">
        <v-bottom-sheet v-model="sheet" inset>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="info" block v-bind="attrs" v-on="on">
              Post Enquiry
            </v-btn>
          </template>
          <v-sheet class="pa-2 rounded-lg text-center" height="auto">
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              color="primary"
              v-if="loading"
              top
            />
            <div class="my-1 title">Enquiry Form</div>
            <v-divider />
            <div class="px-4">
              <v-form
                @submit.prevent="postEnquiry"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  v-model="enquiry.name"
                  :rules="nameRules"
                  label="Full Name"
                  type="text"
                />
                <v-text-field
                  v-model="enquiry.contact"
                  :rules="contactRules"
                  label="Contact Number"
                  type="text"
                />
                <v-text-field
                  v-model="enquiry.city"
                  :rules="cityRules"
                  label="City"
                  type="text"
                />
                <v-textarea
                  :rules="enquiryRules"
                  label="Post your enquiry"
                  v-model="enquiry.enquiryText"
                />
                <v-btn
                  color="info"
                  type="submit"
                  rounded
                  large
                  @click="validate"
                  :disabled="!valid"
                >
                  Post your enquiry
                </v-btn>
              </v-form>
            </div>
          </v-sheet>
        </v-bottom-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Property from "../api/Property";
import EnquiryFormMixins from "../mixins/EnquiryFormMixins";
export default {
  name: "property-details",
  props: ["id"],
  mixins: [EnquiryFormMixins],
  components: {},
  data() {
    let baseUrl = window.location.origin + "/m/ShowPropertyDetails/" + this.id;
    return {
      copyUrl: baseUrl,
      share_sheet: false,
      sheet: false,
      valid: true,
      editedPrice: "",
      loading: false,
      imgURL: "https://100milesrealtors.com/routes/storage/images",
      shareLinks: [
        {
          title: "whatsapp",
          icon: "fab fa-whatsapp",
          link: "https://wa.me/?text=" + baseUrl,
        },
        {
          title: "facebook",
          icon: "fab fa-facebook",
          link: "https://www.facebook.com/sharer/sharer.php?u=" + baseUrl,
        },
        {
          title: "twitter",
          icon: "fab fa-twitter",
          link: "https://twitter.com/intent/tweet?text=" + baseUrl,
        },
        {
          title: "Linked In",
          icon: "fab fa-linkedin",
          link:
            "https://www.linkedin.com/shareArticle?mini=true&url=" + baseUrl,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      basicDetails: (state) => state.property.basicDetails,
      propertyPhoto: (state) => state.property.propertyPhoto,
      amenityDetails: (state) => state.property.amenityDetails,
    }),
  },
  mounted() {
    this.showPropertyDetails();
  },
  beforeUpdate() {
    this.printValue();
  },
  methods: {
    ...mapActions(["viewPropertyDetails"]),
    copyLink() {
      this.$refs.myinput.focus();
      document.execCommand("copy");
    },
    validate() {
      this.$refs.form.validate();
    },
    showPropertyDetails() {
      this.viewPropertyDetails({
        propertyId: this.id,
      });
    },
    printValue() {
      let amt = this.basicDetails[0].price;
      let count = amt.toString().length;
      // console.log("count is :", count, amt);
      switch (count) {
        case 6:
          this.stringVal = amt.toString().substr(0, 3);
          this.editedPrice =
            this.stringVal.toString().substr(0, 1) +
            "." +
            this.stringVal
              .toString()
              .substr(1, this.stringVal.toString().length) +
            " Lac";
          break;
        case 7:
          this.stringVal = amt.toString().substr(0, 4);
          this.editedPrice =
            this.stringVal.toString().substr(0, 2) +
            "." +
            this.stringVal
              .toString()
              .substr(2, this.stringVal.toString().length) +
            " Lac";
          break;
        case 8:
          this.stringVal = amt.toString().substr(0, 3);
          this.editedPrice =
            this.stringVal.toString().substr(0, 1) +
            "." +
            this.stringVal
              .toString()
              .substr(1, this.stringVal.toString().length) +
            " Crore";
          break;
        case 9:
          this.stringVal = amt.toString().substr(0, 4);
          this.editedPrice =
            this.stringVal.toString().substr(0, 2) +
            "." +
            this.stringVal
              .toString()
              .substr(2, this.stringVal.toString().length) +
            " crore";
          break;
      }
    },
    async postEnquiry() {
      this.loading = true;
      console.log(this.basicDetails[0].basic_property_id);
      const fd = new FormData();
      fd.append("name", this.enquiry.name);
      fd.append("contact", this.enquiry.contact);
      fd.append("city", this.enquiry.city);
      fd.append("enquiry", this.enquiry.enquiryText);
      fd.append("basic_property_id", this.basicDetails[0].basic_property_id);
      await Property.postEnquiry(fd)
        .then((response) => {
          if (response.status == 201) {
            this.loading = false;
            this.text = response.data.message;
            this.snackbar = true;
            this.snackbar_color = "success";
            //console.log(response.data);
            this.clearAllField();
          }
        })
        .catch(function (error) {
          this.loading = false;
          this.text = error.response.data.message;
          this.snackbar = true;
          this.snackbar_color = "error";
          //console.log("Error message :", error.message);
        });
    },
  },
};
</script>
<style scoped>
.bottom-bar {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  margin-bottom: 56px;
  height: 56px;
  width: 100%;
}
</style>
